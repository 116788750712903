.Image-wrapper {
  position: relative;
  //   .shadow {
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 100%;
  //     box-shadow: (1px 1px 20px 1px black);
  //   }
  z-index: 1;
  flex-shrink: 0;
  width: 500px;
  height: 500px;
  .nft-frame {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }
  @media (max-width: 1023px) {
    width: 300px;
    height: 300px;
  }
}
