@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
body {
  margin: 0;
  font-family: 'Luckiest Guy', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button {
  font-family: 'Luckiest Guy', cursive;
}
