@import './styles/urlinput.scss';
@import './styles/image-wrapper.scss';
@import './styles/buttons.scss';
@import './styles/frames.scss';
.App {
  padding: 40px 0;
  gap: 20px;
  background: url('../public/bg.jpg');
  background-size: cover;
  background-position: 0 60%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  position: relative;
  justify-content: center;
}
.back {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.main-logo {
  position: relative;
  width: 200px;
}
.no-image {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  font-size: 24px;
  text-shadow: 1px 1px 5px black;
}
.wrapper {
  position: relative;
  padding: 0 40px;
  width: 100%;
  max-width: 1024px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   grid-template-rows: repeat(1, auto);
  //   grid-auto-rows: auto;
  gap: 40px;
  @media (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
  }
}
.toggles {
  width: 100%;
  display: grid;
  flex-direction: column;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, auto);
  grid-auto-rows: auto;
}

.NFT-wrapper {
  cursor: grab;
  width: 550px;
}
.wrapping {
  cursor: grabbing;
}
.NFT-image {
  width: 550px;
  pointer-events: none;
}

.NFT-avatar {
  position: relative;
  top: 5px;
  left: 5px;
  width: 490px;
  height: 490px;
  border-radius: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
.clip {
}
.border {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  pointer-events: none;
}
.logo {
  position: absolute;
  top: 12.5%;
  left: 50%;
  transform: translateX(-50%);
  width: 25%;
  z-index: 1;
}
@media (max-width: 1023px) {
  .App {
    padding: 20px 0;
  }
  .main-logo {
    width: 100px;
  }
  .NFT-wrapper {
    width: 320px;
    position: relative;
    z-index: 1;
  }
  .wrapping {
    cursor: grabbing;
  }
  .NFT-image {
    position: relative;
    width: 320px;
    pointer-events: none;
    cursor: drag;
  }

  .NFT-avatar {
    position: relative;
    width: 300px;
    height: 300px;
  }
}

.wrapper {
  .react-colorful {
    width: 120px;
    height: 100px;
  }
  .react-colorful__saturation {
    border-radius: 4px 4px 0 0;
  }
  .react-colorful__hue {
    height: 10px;
    border-radius: 0 0 4px 4px;
  }
  .react-colorful__hue-pointer {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }
}
