.button {
  background: linear-gradient(180deg, #e7b628 0%, #a9892c 100%);
  border: 5px solid #a58115;
  box-sizing: border-box;
  border-radius: 20px;
  color: white;
  height: 71px;
  font-size: 24px;
  line-height: 1;
  cursor: pointer;
  span {
    pointer-events: none;
    position: relative;
    top: 2px;
  }

  &.dark {
    background: linear-gradient(180deg, #bfad75 0%, #988857 100%);
    border: 5px solid #8a7c4e;
  }
  &.add-image {
    padding: 0 16px;
  }
  &.save-it {
    width: 100%;
    grid-column: 1/3;
    &:disabled {
      opacity: 0.5;
    }
  }
  &.disclaimer {
    height: auto;
    width: 100%;
    grid-column: 1/3;
    font-family: sans-serif;
    font-size: 16px;
    text-align: center;
    padding: 12px 16px;
    line-height: 1.2;
    cursor: unset;
    pointer-events: none;
  }
  @media (max-width: 1023px) {
    height: 46px;
    font-size: 14px;
    border-radius: 14px;
    border: 3px solid #a58115;
  }
  &.dark {
    background: linear-gradient(180deg, #bfad75 0%, #988857 100%);
    border: 3px solid #8a7c4e;
  }
}
