.frames {
  width: 100%;
  grid-column: 1/3;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  display: grid;
}
.frame {
  cursor: pointer;
  width: 100%;
  opacity: 0.7;
  transform: scale(0.8);
  transition: all 0.3s;
  pointer-events: none;
}
.picked {
  opacity: 1;
  transform: scale(1);
  filter: drop-shadow(1px 1px 1px #4444dd);
  border-radius: 10px;
  //   background-color: rgba(0, 0, 0, 0.1);
}
.none {
  color: white;
  font-family: "Luckiest Guy", cursive;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.frame-button {
  cursor: pointer;
  border: none;
  background: transparent;
}
.nft-frame {
  position: relative;
  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    &.picked {
      filter: none;
      opacity: 1;
    }
  }
}
