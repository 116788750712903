.url-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 16px;
  grid-column: 1/3;
  .url-input {
    width: 100%;
    display: block;
    box-sizing: border-box;
    border: 3px solid white;
    border-radius: 20px;
    font-size: 14px;
    color: white;
    background-color: rgba(0, 0, 0, 0);
    line-height: 0;
    padding: 0 16px;
    height: 71px;

    &::placeholder {
      font-family: 'Luckiest Guy', cursive;
      color: white;
      font-size: 18px;
      text-align: center;
      text-shadow: 1px 1px 5px black;
    }
    @media (max-width: 1023px) {
      height: 46px;
      font-size: 14px;
    }
  }
}
